html,
body,
#__next {
  height: 100%;

  --mui-shape-borderRadius: 0;
}

@font-face {
font-family: '__Avenir_0f40bf';
src: url(/_next/static/media/bdd819af903a49ae-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__Avenir_Fallback_0f40bf';src: local("Arial");ascent-override: 106.70%;descent-override: 45.38%;line-gap-override: 0.00%;size-adjust: 101.59%
}.__className_0f40bf {font-family: '__Avenir_0f40bf', '__Avenir_Fallback_0f40bf';font-weight: 400
}

@font-face {
font-family: '__Audrey_c4b1f4';
src: url(/_next/static/media/651ec9fc244eedc1-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__Audrey_Fallback_c4b1f4';src: local("Arial");ascent-override: 78.75%;descent-override: 39.37%;line-gap-override: 0.00%;size-adjust: 101.59%
}.__className_c4b1f4 {font-family: '__Audrey_c4b1f4', '__Audrey_Fallback_c4b1f4';font-weight: 400
}

